import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { ParallaxProvider } from "react-scroll-parallax"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill, scale } from "@cloudinary/url-gen/actions/resize"
import HeroThreeImgs from "../components/shared/hero-three-images-right"
import OurStudios from "../components/contact-us-page/our-studios"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri

        cfHero3Imgs {
          title
          introSentence
          hero3rightimageone {
            altText
            mediaDetails {
              file
            }
          }
          hero3rightimagetwo {
            altText
            mediaDetails {
              file
            }
          }
          hero3rightimagethree {
            altText
            mediaDetails {
              file
            }
          }
        }

        cfCulturContent {
          visvaltitle
          visvalsubtitle
          ourstorytitle
          ourstorysubtitle
          ourstorycontentparagraphs
          ourstorylargeimage {
            altText
            mediaDetails {
              file
            }
          }
          visvalvaluesrepeater {
            visvalrepeatervaluetitle
            visvalrepeatervaluedescription
            visvalrepeatervalueimage {
              altText
              mediaDetails {
                file
              }
            }
          }
          awardsrecogtitle
          awardsrecogintrosentence
          awardsrecogrepeater {
            anawardrepeatertitle
            anawardrepeateryear
            anawardrepeaterawardlogo {
              altText
              mediaDetails {
                file
              }
            }
          }
        }

        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }
    }
  }
`
const PageTemplateOurCulture = ({ data }) => {
  const page = data.wpgraphql.page
  const mainBody = page.cfCulturContent

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  // Our story section Image

  const ourStorySlug = mainBody.ourstorylargeimage.mediaDetails.file
  const ourStoryAlt = mainBody.ourstorylargeimage.altText

  const ourStoryImage = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${ourStorySlug}`
  )

  ourStoryImage.quality("70")
  ourStoryImage.resize(fill().width(800).height(800))
  ourStoryImage.format("auto")

  // Value component

  const AValue = ({ data }) => {
    const slug = data.visvalrepeatervalueimage.mediaDetails.file
    const altText = data.visvalrepeatervalueimage.altText

    const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug}`)

    theImage.quality("70")
    theImage.resize(fill().width(350).height(580))
    theImage.format("auto")

    return (
      <div className="col-12 col-lg-6 mb-100">
        <div className="d-flex">
          <div className="col-3 col-lg-4">
            <AdvancedImage cldImg={theImage} alt={altText} className="mw-100" />
          </div>
          <div className="col col-lg-6 pt-0 pt-sm-4 ps-4">
            <h3 className="mb-3">{data.visvalrepeatervaluetitle}</h3>
            <p>{data.visvalrepeatervaluedescription}</p>
          </div>
        </div>
      </div>
    )
  }

  // An award component
  const AnAward = ({ data }) => {
    const slug = data.anawardrepeaterawardlogo.mediaDetails.file
    const altText = data.anawardrepeaterawardlogo.altText

    const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug}`)

    theImage.quality("80")
    theImage.resize(scale().width(100))
    theImage.format("auto")
    return (
      <div className="border-top pt-4 pb-4 d-flex flex-column flex-md-row align-items-center">
        <div className="col-2 col-md-1 me-md-5">
          <AdvancedImage cldImg={theImage} alt={altText} className="mw-100" />
        </div>
        <div className="flex-grow-1">
          <p className="mb-0">{data.anawardrepeatertitle}</p>
        </div>
        <div className="mt-3 mt-md-0 ps-0 ps-md-4">
          <p className="mb-0">{data.anawardrepeateryear}</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <Layout>
        <ParallaxProvider>
          <HeroThreeImgs pagedata={page.cfHero3Imgs} />
        </ParallaxProvider>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 mb-5">
              <span className="h4-look d-block mb-4">
                {mainBody.visvaltitle}
              </span>
              <h2>{mainBody.visvalsubtitle}</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {mainBody.visvalvaluesrepeater.map((value, index) => {
              return <AValue key={index} data={value} />
            })}
          </div>
        </div>
        <div className="position-relative mb-6 border-bottom border-top">
          <div className="container pt-6 pb-6">
            <div className="row">
              <div className="col-12 col-lg-6 d-flex align-items-center">
                <div className="col col-lg-9">
                  <span className="h4-look d-block mb-3">
                    {mainBody.ourstorytitle}
                  </span>
                  <h2 className="mb-4">{mainBody.ourstorysubtitle}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mainBody.ourstorycontentparagraphs,
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                <AdvancedImage
                  cldImg={ourStoryImage}
                  alt={ourStoryAlt}
                  className="mw-100"
                />
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block background_blue"></div>
        </div>

        <div className="container mb-6">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-3">
              <h2>{mainBody.awardsrecogtitle}</h2>
            </div>
            <div className="col-12 col-lg-8 col-xl-9">
              <p className="mb-5">{mainBody.awardsrecogintrosentence}</p>
              {mainBody.awardsrecogrepeater.map((value, index) => {
                return <AnAward key={index} data={value} />
              })}
            </div>
          </div>
        </div>

        <ParallaxProvider>
          <OurStudios />
        </ParallaxProvider>
      </Layout>
    </>
  )
}

export default PageTemplateOurCulture

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return <>{seoTags}</>
}
