import React, { useRef } from "react"
import { Parallax } from "react-scroll-parallax"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { isSafariLess16 } from "../utils.js"

const HeroThreeImgs = ({ pagedata }) => {
  const boxRef = useRef()

  const isSafariV16 = isSafariLess16()

  const title = pagedata.title
  const tagline = pagedata.introSentence

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })
  // img 1
  const imgOneSlug = pagedata.hero3rightimageone.mediaDetails.file
  const imgOneAlt = pagedata.hero3rightimageone.altText

  const heroImgOne = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${imgOneSlug}`
  )

  heroImgOne.quality("70")
  heroImgOne.resize(fill().width(700).height(1370))
  heroImgOne.format("auto")

  // img 2
  const imgTwoSlug = pagedata.hero3rightimagetwo.mediaDetails.file
  const imgTwoAlt = pagedata.hero3rightimagetwo.altText

  const heroImgTwo = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${imgTwoSlug}`
  )

  heroImgTwo.quality("70")
  heroImgTwo.resize(fill().width(700).height(1370))
  heroImgTwo.format("auto")

  // img 3
  const imgThreeSlug = pagedata.hero3rightimagethree.mediaDetails.file
  const imgThreeAlt = pagedata.hero3rightimagethree.altText

  const heroImgThree = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${imgThreeSlug}`
  )

  heroImgThree.quality("70")
  heroImgThree.resize(fill().width(700).height(1370))
  heroImgThree.format("auto")

  return (
    <>
      <div
        className="hero-md d-flex flex-column flex-lg-row align-items-center darkgrey_bg mb-6 position-relative"
        ref={boxRef}
      >
        <div className="container">
          <div id="hide-row-when-nav-open" className="row gx-2">
            <div className="col-12 col-lg-6 color-white animated-headline align-self-center pt-5 mt-5 pt-lg-0 mt-lg-0 mb-5 pb-3 pb-lg-0 mb-lg-0">
              <span className="mb-5 h4 d-block text-uppercase header-fade-in">
                {title}
              </span>
              <div className="animated-headline-wrap">
                <h1 className="h2 new_style">
                  <span
                    className={`new_style_addit ${
                      isSafariV16 ? "safari_16" : ""
                    }`}
                  >
                    {tagline}
                  </span>
                </h1>
              </div>
            </div>
            <div className="col-4 col-lg-2">
              <AdvancedImage
                cldImg={heroImgOne}
                alt={imgOneAlt}
                className="mw-100  header-fade-in"
              />
            </div>
            <div className="col-4 col-lg-2">
              <Parallax speed={5} translateY={[0, -20]}>
                <AdvancedImage
                  cldImg={heroImgTwo}
                  alt={imgTwoAlt}
                  className="mw-100  header-fade-in"
                />
              </Parallax>
            </div>
            <div className="col-4 col-lg-2">
              <Parallax speed={5} translateY={[0, 10]}>
                <AdvancedImage
                  cldImg={heroImgThree}
                  alt={imgThreeAlt}
                  className="mw-100  header-fade-in"
                />
              </Parallax>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroThreeImgs
